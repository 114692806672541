import { logMissingTranslation } from 'localization';
import { DEFAULT_LOCALE_CODE } from './config/locale';

export default defineI18nConfig(() => ({
  fallbackLocale: DEFAULT_LOCALE_CODE,
  // It would add warnings to the console additionally to errors
  fallbackWarn: false,
  legacy: false,
  missing: (missingLocale: string, missingKey: string) => {
    logMissingTranslation({
      currentFallbackLocale: DEFAULT_LOCALE_CODE,
      missingKey,
      missingLocale,
    });
  },
  warnHtmlMessage: false,
}));
